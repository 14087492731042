import React from "react";
import { useAppSelector } from "../../store/hooks";

interface ConfirmationModalProps {
  showDialog: boolean;
  onCloseDialog: () => void;
  itemName: any;
  setItem: any;
  bankId?: string;
  fetchData?: (page: number) => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  showDialog,
  onCloseDialog,
  itemName,
  setItem,
  bankId,
  fetchData,
}) => {
  const { user } = useAppSelector((state) => state.user);
  const token = user?.token;

  const handleConfirmDelete = async () => {
    if (itemName) {
      try {
        const headers: HeadersInit = new Headers();
        headers.append("Content-Type", "application/json");

        if (token) {
          headers.append("Authorization", `Bearer ${token}`);
        }
        const url = bankId
          ? `${process.env.REACT_APP_SERVER_URL}bank/${bankId}`
          : `${process.env.REACT_APP_SERVER_URL}devicewhitelisting/${itemName?.id}`;
        const response = await fetch(url, {
          method: "DELETE",
          headers: headers,
        });
      } finally {
        onCloseDialog();
        fetchData && fetchData(1);
        setItem(null);
      }
    }
  };

  return (
    <>
      {showDialog && (
        <div
          className="modal fade show d-block"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 style={{ fontWeight: "bold" }} className="modal-title">
                  Confirm Deletion
                </h5>
                <button type="button" className="close" onClick={onCloseDialog}>
                  <span>&times;</span>
                </button>
              </div>
              <div style={{ display: "flex" }} className="modal-body ">
                <span>
                  {" "}
                  Are you sure you want to delete{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {itemName?.deviceModel || itemName.bankName}
                  </span>
                  ?
                </span>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onCloseDialog}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleConfirmDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConfirmationModal;
