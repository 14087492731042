const routeNames = {
  login: "/login",
  signup: "/signup",
  landing: "/landing",
  users: "/users",
  subscriptions: "/subscriptions",
  banks: "/banks",
  home: "/home",
  analytics: "/analytics",
  roles: "/roles",
  devices: "/devices",
  sdk: "/sdk",
  notFound: "/404",
};

export default routeNames;
