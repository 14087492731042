import React, { useState, useEffect } from "react";
import { Card, CardBody, Button, FormGroup, Input, Label } from "reactstrap";
import "../../styles/ActiveUsersTable.css";

interface Role {
  id: string;
  name: string;
  accessiblePages: string[];
}

// Static pages array
const pages = [
  "dashboard",
  "users",
  "subscriptions",
  "banks",
  "analytics",
  "roles",
  "devices",
  "sdk",
];

const UserRoles = () => {
  const [roles, setRoles] = useState<Role[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [editingRoleId, setEditingRoleId] = useState<string | null>(null);

  // Fetch roles data
  const fetchRoles = async () => {
    setIsDataLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}userRoles`
      );
      const data = await response.json();
      setRoles(
        data.map((role: Role) => ({
          ...role,
          accessiblePages: role.accessiblePages || [],
        }))
      );
    } catch (error) {
      console.error("Failed to fetch user roles", error);
    } finally {
      setIsDataLoading(false);
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  const handleCheckboxChange = (roleId: string, page: string) => {
    setRoles((prevRoles) =>
      prevRoles.map((role) =>
        role.id === roleId
          ? {
              ...role,
              accessiblePages: role.accessiblePages.includes(page)
                ? role.accessiblePages.filter((p) => p !== page)
                : [...role.accessiblePages, page],
            }
          : role
      )
    );
  };

  const saveRole = async (roleId: string) => {
    const roleToUpdate = roles.find((role) => role.id === roleId);
    if (roleToUpdate) {
      try {
        await fetch(`${process.env.REACT_APP_SERVER_URL}userRoles/update/`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(roleToUpdate),
        });
        fetchRoles(); // Refresh roles
        setEditingRoleId(null); // Stop editing
      } catch (error) {
        console.error("Failed to update role", error);
      }
    }
  };

  return (
    <div className="container">
      <div className="main-content">
        <div className="header">
          <h1>Edit Roles</h1>
        </div>
        <Card className="card-body">
          <CardBody>
            {isDataLoading ? (
              <div>Loading...</div>
            ) : (
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Role Name</th>
                      <th>Accessible Pages</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {roles.map((role) => (
                      <tr key={role.id}>
                        <td>{role.name}</td>
                        <td>
                          <div className="checkbox-row">
                            {pages.map((page) => (
                              <div key={page} className="checkbox-item">
                                <Input
                                  type="checkbox"
                                  checked={role.accessiblePages.includes(page)}
                                  onChange={() =>
                                    handleCheckboxChange(role.id, page)
                                  }
                                  disabled={editingRoleId !== role.id} // Disable checkboxes if not editing
                                />
                                <Label>{page}</Label>
                              </div>
                            ))}
                          </div>
                        </td>
                        <td>
                          {editingRoleId === role.id ? (
                            <Button
                              color="primary"
                              onClick={() => saveRole(role.id)}
                            >
                              Save
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              onClick={() => setEditingRoleId(role.id)}
                            >
                              Edit
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default UserRoles;
