import React from "react";

const LatestSdk = () => {
  return (
    <div className="container">
      <div className="main-content">
        <div className="header">
          <h2 style={{ marginBottom: "35px" }}>
            Please download the latest dev release from the following url
          </h2>
          <a href="https://github.com/HassanJaved89/OTPAlternative/releases/latest">
            https://github.com/HassanJaved89/OTPAlternative/releases/latest
          </a>
        </div>
      </div>
    </div>
  );
};

export default LatestSdk;
