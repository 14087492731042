import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import Loadable from "../components/Loadable";
import routeNames from "./routeNames";
import Sidebar from "../components/Sidebar/sidebar";
import PrivateRoute from "../PrivateRoute";
import UserRoles from "../pages/UserRoles";
import { useAppSelector } from "../store/hooks";
import DeviceWhitelisting from "../pages/DeviceWhitelisting";
import LatestSdk from "../pages/Sdk";

const Page404 = Loadable(lazy(() => import("../pages/Page404")));
const Landing = Loadable(lazy(() => import("../pages/Landing")));
const Users = Loadable(lazy(() => import("../pages/Users")));
const Banks = Loadable(lazy(() => import("../pages/Banks")));
const Subscriptions = Loadable(lazy(() => import("../pages/Subscriptions")));
const Analytics = Loadable(lazy(() => import("../pages/Analytics")));

const UserRoutes = () => {
  return useRoutes([
    {
      path: "/",
      element: (
        <>
          <AuthLayout />
          <Sidebar />
        </>
      ),
      children: [
        // Protected routes
        {
          path: routeNames.landing,
          element: <PrivateRoute allowedPages={["dashboard"]} />,
          children: [{ path: "", element: <Landing /> }],
        },

        {
          path: routeNames.users,
          element: <PrivateRoute allowedPages={["users"]} />,
          children: [{ path: "", element: <Users /> }],
        },
        {
          path: routeNames.banks,
          element: <PrivateRoute allowedPages={["banks"]} />,
          children: [{ path: "", element: <Banks /> }],
        },
        {
          path: routeNames.subscriptions,
          element: <PrivateRoute allowedPages={["subscriptions"]} />,
          children: [{ path: "", element: <Subscriptions /> }],
        },
        {
          path: routeNames.analytics,
          element: <PrivateRoute allowedPages={["analytics"]} />,
          children: [{ path: "", element: <Analytics /> }],
        },
        {
          path: routeNames.roles,
          element: <PrivateRoute allowedPages={["roles"]} />,
          children: [{ path: "", element: <UserRoles /> }],
        },
        {
          path: routeNames.devices,
          element: <PrivateRoute allowedPages={["devices"]} />,
          children: [{ path: "", element: <DeviceWhitelisting /> }],
        },
        {
          path: routeNames.sdk,
          element: <PrivateRoute allowedPages={["sdk"]} />,
          children: [{ path: "/sdk", element: <LatestSdk /> }],
        },

        // Fallback routes
        { path: routeNames.notFound, element: <Page404 /> },
        { path: "/", element: <Navigate to={routeNames.landing} /> },
        { path: "*", element: <Navigate to={routeNames.landing} /> },
      ],
    },
  ]);
};

export default UserRoutes;
